<template>
  <q-page padding>
    <div class="column items-center">
      <template v-if="!hasRole('guest')">
        <div class="col q-py-xs">
          <m-image
            :src="user.picture || require('assets/avatar.png')"
            class="avatar avatar-toolbar cursor-pointer"
          />
        </div>
        <div class="col text-weight-medium">
          {{ user.display_name }}
        </div>
      </template>
      <m-guest-blocker v-else />
      <div class="col full-width q-mt-md">
        <q-list>
          <q-item
            v-for="menu in filteredMenu"
            :key="menu.icon"
            v-ripple
            clickable
            :to="hasRole('guest') && (menu.link.name !== 'Logout') ? '' : menu.link"
          >
            <q-item-section avatar>
              <q-icon :name="menu.icon" />
            </q-item-section>
            <q-item-section :aria-label="menu.label">
              {{ menu.label }}
            </q-item-section>
          </q-item>
          <q-item>
            <q-item-section v-if="isDerby" class="q-mt-sm">
              <small class="self-center text-grey-7 q-mb-md">By</small>
              <img :src="require('assets/Kinto_Blue_height_30.png')" height="30px" class="self-center q-mb-md">
              <small class="row self-center text-grey-7 q-mb-xs text-center powered">
                {{ $t('powered_by_partner', {partner: 'DerbyGo', intermediate: 'KINTO'}) }}
              </small>
            </q-item-section>
            <q-item-section v-else class="q-mt-sm">
              <small class="row self-center text-grey-5 q-mb-xs">{{ $t('powered_by') }}</small>
              <img :src="require('assets/mobilleo-logo-blue.png')" height="30px" class="self-center">
            </q-item-section>
          </q-item>
          <q-item class="version-number">
            Version: {{ version }}
          </q-item>
        </q-list>
      </div>
    </div>
  </q-page>
</template>

<script>
import authentication from 'mixins/authentication'
import { mapGetters } from 'vuex'
import { get } from 'api/dashboard'
import { MGuestBlocker, MImage } from 'components'

const version = process.env.VUE_APP_VERSION_NUMBER

export default {
  name: 'Profile',
  components: { MGuestBlocker, MImage },
  mixins: [authentication],
  data () {
    return {
      version,
      spend: null,
      budget: null,
      overBudget: null,
      profileMenu: [
        {
          icon: 'fa fa-user',
          label: this.$t('account_settings'),
          link: { hash: '/user-settings/account', query: { label: 'home' } }
        },
        {
          icon: 'grade',
          label: this.$t('favourite_addresses'),
          link: { hash: '/user-settings/address-list' }
        },
        {
          icon: 'far fa-passport',
          label: this.$t('travel_documents'),
          link: { hash: '/user-settings/passport' }
        },
        {
          icon: 'fas fa-credit-card',
          label: this.$tc('payment_card.card', 2),
          link: { hash: '/user-settings/payments' }
        },
        {
          icon: 'far fa-id-card',
          label: this.$tc('travel_providers.travel_providers'),
          link: { hash: '/travel-providers' }
        },
        {
          icon: 'far fa-car',
          label: this.$t('carclub.membership.membership'),
          link: { hash: '/user-settings/carclub' }
        },
        {
          icon: 'far fa-ship',
          label: this.$t('ferry.membership.northlink'),
          link: { hash: '/user-settings/ferry' }
        },
        {
          icon: 'far fa-cogs',
          label: this.$t('transport_preferences'),
          link: { hash: '/user-settings/travel-policy' }
        },
        {
          icon: 'far fa-scroll',
          label: this.$t('legal_agreements'),
          link: { hash: '/user-settings/legal' }
        },
        {
          icon: 'far fa-terminal',
          label: this.$t('debug'),
          link: { hash: '/system-settings/debug' },
          role: 'admin'
        },
        {
          icon: 'far fa-sign-out',
          label: this.$t('sign_out'),
          link: { name: 'Logout' }
        }
      ]
    }
  },
  computed: {
    ...mapGetters(['ui', 'user', 'organisation', 'partner']),
    withinBudget () {
      if (!this.spend && !this.budget) return true
      return this.spend?.amount <= this.budget?.amount
    },
    budgetLabel () {
      return this.withinBudget
        ? this.$t('you_are_within_budget')
        : this.$t('you_are_over_budget')
    },
    filteredMenu () {
      let filteredMenu = [ ...this.profileMenu ]

      if (['gohi'].includes(this.partner.slug) || !this.hasRole(['member', 'middleoffice'])) {
        filteredMenu = filteredMenu.filter(item => item.label !== this.$tc('mobility_budget.default', 2))
      }

      if (['derbygo'].includes(this.partner.slug)) {
        filteredMenu = filteredMenu.filter(item => {
          return ![
            this.$tc('mobility_budget.default', 2),
            this.$t('carclub.membership.membership'),
            this.$t('travel_documents'),
            this.$t('transport_preferences')
          ].includes(item.label)
        })
      }

      if (!['gohi'].includes(this.partner.slug)) {
        filteredMenu = filteredMenu.filter(item => item.label !== this.$t('ferry.membership.northlink'))
      }

      // filter by roles
      filteredMenu = filteredMenu.filter(item => {
        return !item.role ? true : this.hasRole(item.role)
      })

      return filteredMenu
    },
    isDerby () {
      return this.$store.getters['partner'].slug === 'derbygo'
    }
  },
  async created () {
    const response = await get()
    this.spend = response.data.spend
    this.budget = response.data.current_month_budget
  }
}
</script>

<style lang="stylus" scoped>
.powered
  padding: 0 75px

.q-page
  padding-bottom 55px

.version-number
    font-style italic
    margin-top 8px
    color #aaa
    font-size 80%
</style>
